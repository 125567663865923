import * as Sentry from "@sentry/nuxt";

const environment = useRuntimeConfig().public.environment;

if (environment !== "local") {
  Sentry.init({
    enabled: environment !== "local",
    dsn: "https://d61e80913cdf5ec18f38486935d22f0f@o558932.ingest.us.sentry.io/4507375432564736",
    environment: environment,
  });
}
